import React, { FC } from 'react'
import { StyledFooter, FooterInner, FooterLinks } from './FooterSimple.styled'
import theme from '../../../styles/theme'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import FooterLinksList from '../FooterLinksList'

interface FooterProps {
  isGamePage?: boolean
  className?: string | unknown
}

const FooterSimple: FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <StyledFooter className={className} id="footer-simple-container">
      <FooterInner id="footer-simple-inner">
        <FooterLinks>
          <FooterLinksList />
        </FooterLinks>
        <p style={{ color: theme.colors.darkGreyAlt, textAlign: 'center', margin: '10px 0 0 0', fontSize: '1.2rem' }}>
          &copy;
          {` ${t('components.footer.intelligentHealth', {
            date: new Date().getFullYear(),
          })}`}
        </p>
      </FooterInner>
    </StyledFooter>
  )
}

export default FooterSimple
