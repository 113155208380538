import { date, object, string } from 'yup'

export const getCreateFamilyMemberSchema = (t2, maxValidDate) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    firstName: string().max(50, t('player.errors.maxCharFN')).nullable().required(t('validation.req')),
    publicName: string().max(16, t('player.errors.maxCharDN')).nullable().required(t('validation.req')),
    postCode: string().max(20, t('errors.formik.postCode.max')).nullable().required(t('validation.req')),
    gender: string().nullable().required(t('validation.req')),
    birthDate: date()
      .min(maxValidDate, t('player.errors.childValidBirthDate'))
      .nullable()
      .required(t('player.errors.birthDate')),
  })
}

export interface ChildProfileProps {
  firstName: string
  birthDate: string
  publicName: string
  postCode: string
  gender: string
  submit: null | string
}
