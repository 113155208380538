import React, { FC } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import PartnerLogos from '../../PartnerLogos/PartnerLogos'
import FooterMobileImage from '../../../assets/images/svg/footer-mobile.svg'
import FooterDesktopImage from '../../../assets/images/svg/footer-desktop.svg'
import {
  Container,
  ImageContainer,
  FooterWrapper,
  FooterText,
  FooterTextContainer,
  FooterLinks,
} from './FooterWithBackground.styled'
import theme from '../../../styles/theme'
import useGame from '../../../contexts/GameContext/useGame'
import usePageContext from '../../../hooks/usePageContext'
import { useLocation } from '@gatsbyjs/reach-router'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { SanityPartner } from 'src/generated/api'
import FooterLinksList from '../FooterLinksList'

interface Props {
  isGamePage?: boolean
}

const Footer: FC<Props> = () => {
  const { width } = useWindowSize()
  const footerImage = width > 1023 ? <FooterDesktopImage /> : <FooterMobileImage />
  const { game } = useGame()
  const { pathname } = useLocation()
  const { showPartnersLogos, sanityData } = usePageContext()
  const { t } = useTranslation()
  const partnersLimit = game?.content?.numberOfPartnersInFooter || undefined
  const isGamePage = game?.info.slug ? pathname.includes(game?.info.slug) : false

  const isPartnerPageLogos = game?.content?.partners && game?.content?.partners.length > 0
  const isFooterLogos = game?.content?.footerPartners && game?.content.footerPartners.length > 0

  const partners: SanityPartner[] =
    (game && partnersLimit !== undefined && isPartnerPageLogos && game?.content?.partners) ||
    (isFooterLogos && game?.content?.footerPartners) ||
    sanityData?.sanityHomepage?.partners ||
    []

  const partnerLogos =
    (showPartnersLogos === true &&
      partners
        ?.slice(0, partnersLimit)
        .filter((e) => e)
        .map((v) => {
          return {
            id: v.id,
            img: v.logo,
            alt: v.name,
            href: v.websiteUrl,
          }
        })) ||
    []

  return (
    <Container home={pathname === '/'} id="footer-container">
      <ImageContainer>{footerImage}</ImageContainer>
      <FooterWrapper id="footer-wrapper">
        <FooterTextContainer>
          <FooterText partners={partnerLogos.length > 0}>
            <p style={{ color: theme.colors.darkGreyAlt }}>
              &copy;
              {` ${t('components.footer.intelligentHealth', {
                date: new Date().getFullYear(),
              })}`}
            </p>
            <FooterLinks partners={partnerLogos.length > 0} isGamePage={!isGamePage}>
              <FooterLinksList />
            </FooterLinks>
          </FooterText>
        </FooterTextContainer>
        {partnerLogos.length > 0 && <PartnerLogos logos={partnerLogos} isFooter />}
      </FooterWrapper>
    </Container>
  )
}

export default Footer
