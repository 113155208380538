import React, { FC, useCallback, useState } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import Input from '../../../../components/Input/Input'
import Button from '../../../../components/Button/Button'
import SEO from '../../../../components/seo'
import { Error, FormItem, FormItemGrid } from '../../../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import { ErrorMessage, Formik } from 'formik'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import debounce from 'lodash/debounce'
import useNavigate from '../../../../hooks/useNavigate'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import DateInput from '../../../../components/DateInput'
import theme from '../../../../styles/theme'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getNameDetailsSchema } from './validation'
import { useManagePlayers } from '../../hooks/useManagePlayers'
import { fetchData } from '../../../../client'
import { CheckPublicNameDocument, CheckPublicNameQuery, CheckPublicNameQueryVariables } from '../../../../generated/api'
import getValidDate from '../../../../utils/getValidDate'

const RegisterDetails: FC = () => {
  const { onUpdatePlayer } = useManagePlayers()
  const { user } = useAuth()
  const { t } = useTranslation()
  const [isPublicNameAvailable, setIsPublicNameAvailable] = useState<string | null>(null)
  const navigate = useNavigate()
  const nameDetailsSchema = getNameDetailsSchema(t, getValidDate(false))
  const [isBirthTouched, setIsBirthTouched] = useState(false)

  const verifyIsPublicNameAvailable = useCallback(
    debounce(async (value: string) => {
      try {
        const res = await fetchData<CheckPublicNameQuery, CheckPublicNameQueryVariables>(CheckPublicNameDocument, {
          name: value,
        })
        const isFree = res?.checkPublicName
        setIsPublicNameAvailable(null)
        if (!isFree) {
          setIsPublicNameAvailable(t('errors.api.signUp.displayNameTaken'))
        }
      } catch (e) {
        setIsPublicNameAvailable(t('errors.api.signUp.error'))

        console.log(e)
      }
    }, 400),
    [],
  )

  return (
    <>
      <SEO title={t('signUp.title')} />
      <HeadingWrapper>
        <Heading data-testid="page-title">{t('signUp.nameDetails.title')}</Heading>
        <SubHeading as="h2" secondaryForm veryTall data-testid="page-subtitle">
          {t('signUp.nameDetails.subTitle')}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="left">
        <Formik
          enableReinitialize
          initialValues={{
            firstName: '',
            publicName: '',
            birthDate: '',
            submit: null,
          }}
          validationSchema={nameDetailsSchema}
          onSubmit={async (values, { setErrors }) => {
            try {
              if (user?.id) {
                await onUpdatePlayer({
                  id: user?.id,
                  info: {
                    publicName: values.publicName,
                  },
                  profile: {
                    firstName: values.firstName,
                    birthDate: values.birthDate,
                  },
                })
                await navigate('/signup/game-details', { replace: true })
              }
            } catch (e) {
              console.log('e ===', e)
              if (e?.errors?.[0].message.includes('Display name is already taken')) {
                setErrors({ submit: t('errors.api.signUp.displayNameTaken') || undefined })
              } else if (e?.errors?.[0].message.includes('Invalid birth date.')) {
                setErrors({ submit: t('errors.api.account.birthDate') || undefined })
              }
              setErrors({ submit: t('errors.signup') || undefined })
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldError,
            setFieldValue,
            isValid,
            dirty,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <FormItem>
                  <Input
                    type="text"
                    id="firstName"
                    text={t('player.firstName') || ''}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstName ? errors.firstName : undefined}
                    autoComplete="name"
                    required
                    inputProps={{ 'data-testid': 'first-name-input' }}
                  />
                </FormItem>

                <FormItem>
                  <Input
                    type="text"
                    id="publicName"
                    text={t('player.displayName') || ''}
                    tooltip={<Tooltip text={t('player.displayNameTooltip') || ''} />}
                    value={values.publicName}
                    onChange={(e) => {
                      setFieldValue('publicName', e.target.value)
                      verifyIsPublicNameAvailable(e.target.value)
                    }}
                    onBlur={handleBlur}
                    error={
                      (touched.publicName && errors.publicName) ||
                      (isPublicNameAvailable ? isPublicNameAvailable : undefined)
                    }
                    autoComplete="username"
                    required
                    inputProps={{ 'data-testid': 'display-name-input' }}
                  />
                </FormItem>

                <fieldset style={{ margin: 0 }}>
                  <legend>Date of birth</legend>
                </fieldset>
                <FormItemGrid data-testid="date-picker">
                  <DateInput
                    name="birthDate"
                    onChange={(value) => {
                      setFieldValue('birthDate', value)
                    }}
                    setFieldError={setFieldError}
                    value={values.birthDate}
                    maxYear={getValidDate(false).getFullYear()}
                    setIsTouched={setIsBirthTouched}
                  />
                </FormItemGrid>
                {(isBirthTouched || touched.birthDate) && errors?.birthDate && (
                  <>
                    <p
                      style={{ color: theme.colors.error, display: 'flex', justifyContent: 'center' }}
                      data-testid="birthday-error-msg"
                    >
                      {errors?.birthDate}
                    </p>
                    <br />
                  </>
                )}

                <ErrorMessage name="submit" render={() => <Error className="checkboxError" />} />
                <br />
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!dirty || !isValid || !!isPublicNameAvailable}
                  text={t('common.continue')}
                  onClick={handleSubmit}
                  secondary
                  type={'submit'}
                  testID="continue-button"
                />
              </form>
            )
          }}
        </Formik>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query RegisterDetails($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default RegisterDetails
