import useGame from '../../contexts/GameContext/useGame'
import usePageContext from '../../hooks/usePageContext'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { FooterLink, StyledHelpText } from './FooterWithBackground/FooterWithBackground.styled'
import ContextLink from '../ContextLink'
import React from 'react'

const FooterLinksList = () => {
  const { game } = useGame()
  const { pageList } = usePageContext()
  const { t } = useTranslation()

  const pageExists = (page: string) => pageList?.some((x) => x.includes(page))

  const handleOpenHelpDesk = () => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.FreshworksWidget('open')
    }
  }

  return (
    <>
      <div>
        {pageExists('about-us') && (
          <FooterLink>
            <ContextLink to="/about-us">{t('components.footer.aboutUs')}</ContextLink>
          </FooterLink>
        )}
        <FooterLink>
          <StyledHelpText onClick={handleOpenHelpDesk}>{t('components.footer.help')}</StyledHelpText>
        </FooterLink>
        {game?.content?.facebookUsername && (
          <FooterLink>
            <a
              href={`https://www.facebook.com/${game.content.facebookUsername}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </FooterLink>
        )}
        {game?.content?.twitterUsername && (
          <FooterLink>
            <a href={`https://x.com/${game.content.facebookUsername}`} target="_blank" rel="noopener noreferrer">
              X
            </a>
          </FooterLink>
        )}
      </div>
      <div>
        {pageExists('privacy') && (
          <FooterLink>
            <ContextLink to="/privacy">{t('components.footer.privacy')}</ContextLink>
          </FooterLink>
        )}
        {pageExists('accessibility') && (
          <FooterLink>
            <ContextLink to="/accessibility">{t('components.footer.accessibility')}</ContextLink>
          </FooterLink>
        )}
        {pageExists('cookies') && (
          <FooterLink>
            <ContextLink to="/cookies">{t('components.footer.cookies')}</ContextLink>
          </FooterLink>
        )}
        {pageExists('terms-and-conditions') && (
          <FooterLink>
            <ContextLink to="/terms-and-conditions">{t('components.footer.tAndC')}</ContextLink>
          </FooterLink>
        )}
        {/*<ChangeLanguage isGamePage={isGamePage} />*/}
      </div>
    </>
  )
}

export default FooterLinksList
