import { object, string, date } from 'yup'

export const getYourDetailsSchema = (t2, validDate, isChild) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    publicName: string()
      .trim()
      .min(1, t('errors.formik.publicName.min'))
      .max(16, t('errors.formik.publicName.max'))
      .required(t('errors.formik.publicName.req')),
    firstName: string()
      .trim()
      .min(1, t('errors.formik.firstName.min'))
      .max(50, t('errors.formik.firstName.max'))
      .required(t('player.errors.firstName')),
    birthDate: date()
      .test({
        name: 'is child',
        message: isChild ? t('player.errors.childValidBirthDate') : t('player.errors.adultEditValidBD'),
        test: (value) => {
          if (!value) return false
          if (isChild) return value > validDate
          return value < validDate
        },
      })
      .nullable()
      .required(t('player.errors.birthDate')),
    email: string().email(t('errors.email.valid')),
    postCode: string()
      .trim()
      .min(1, t('errors.formik.postCode.min'))
      .max(20, t('errors.formik.postCode.max'))
      .required(t('errors.formik.postCode.req')),
    gender: string().required(t('player.errors.gender')),
    // preferredLanguage: Yup.string()
    //   .oneOf(
    //     SUPPORTED_LANGUAGES.map(({ localeCode }) => localeCode),
    //     t('player.errors.preferredLanguage'),
    //   )
    //   .required(t('player.errors.preferredLanguage')),
  })
}

export interface ProfileData {
  publicName: string
  firstName: string
  birthDate: string
  email: string
  postCode: string
  gender: string
  card?: string | number
  appPlayerNumber: string | number
  // preferredLanguage: user?.preferredLanguage || '',
  submit: null | string
}
