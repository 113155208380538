import React, { FC, useCallback, useEffect, useState } from 'react'
import { Heading } from '../../components/Heading/Heading.styled'
import Wrapper from '../../components/Wrapper/Wrapper'
import GenderInput from '../../components/GenderInput'
import SEO from '../../components/seo'
import { Error, FormItem, FormItemGrid } from '../../components/FormElements/FormElements.styled'
import Button from '../../components/Button/Button'
import Input from '../../components/Input/Input'
import useAuth from '../../contexts/AuthContext/useAuth'
import { ErrorMessage, Formik, FormikErrors } from 'formik'
import ChangePasswordForm from './components/changePassword/ChangePasswordForm'
import DateInput from '../../components/DateInput'
import { Puff } from 'react-loader-spinner'
import Tooltip from '../../components/Tooltip/Tooltip'
import theme from '../../styles/theme'
import { Slide, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ConnectCardLink from '../../components/ConnectCardLink'
import Section from '../../components/Section'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGame from '../../contexts/GameContext/useGame'
import {
  CheckPublicNameDocument,
  CheckPublicNameQuery,
  CheckPublicNameQueryVariables,
  GameStatus,
  PostCodeInput,
  TagTypes,
} from '../../generated/api'
import { useManagePlayers } from '../signup/hooks/useManagePlayers'
import FamilyMemberSelect from '../../components/FamilymemberSelect'
import CompleteGameSetup from '../../components/CompleteGameSetup'
import HandleEmailForm from './components/changeEmail/HandleEmailForm'
import { getYourDetailsSchema, ProfileData } from './validation'
import NewsletterButton from './components/newsletter/newsletterButton'
import { IPageProps } from '../../contexts/PageContext'
import debounce from 'lodash/debounce'
import { fetchData } from '../../client'
import { validatePostcode } from '../signup/helpers/validatePostcode'
import RemoveFromFamilyButton from './components/removeFromFamilyButton'
import LeaveFamilySection from './components/leaveFamilySection'
import GameSection from './components/GameSection'
import useGameRestricted from '../../hooks/useGameRestricted'
import DeleteAccountButton from './components/deleteAccount/index'
import getValidDate from '../../utils/getValidDate'
//import { SUPPORTED_LANGUAGES } from '../../utils/constants'

const ManageAccountPage: FC<IPageProps<Queries.ManageAccountPageQuery>> = () => {
  const { user, masterUser, family, setUser } = useAuth()
  const { t } = useTranslation()
  //const { changeLanguage } = useI18next()
  const { onUpdatePlayer, onCreatePostCodeRecord } = useManagePlayers()
  const { activeGame: game } = useGame()
  const [isLoading, setIsLoading] = useState(false)
  const [isPublicNameAvailable, setIsPublicNameAvailable] = useState<string | null>(null)
  const [postCodeData, setPostCodeData] = useState<PostCodeInput>()
  const { isGameRestricted } = useGameRestricted()

  const yourDetailsSchema = getYourDetailsSchema(t, getValidDate(Boolean(user?.role.isChild)), user?.role.isChild)

  const hasFamily = family?.length > 1
  const parentId = family?.length && family?.[0].role.parentId
  const isParent = parentId === masterUser?.id
  const linkedAccount = user?.id !== masterUser?.id && user?.role.isChild === false
  const isMasterLinkedToFamily =
    user?.id === masterUser?.id && hasFamily && masterUser?.role.parentId !== masterUser?.id

  useEffect(() => {
    ;(async () => {
      if (!isParent && masterUser?.id) {
        setIsLoading(true)
        await setUser(masterUser.id)
        setIsLoading(false)
      }
    })()
  }, [isParent])

  const verifyIsPublicNameAvailable = useCallback(
    debounce(async (value: string) => {
      try {
        const res = await fetchData<CheckPublicNameQuery, CheckPublicNameQueryVariables>(CheckPublicNameDocument, {
          name: value,
        })
        const isFree = res?.checkPublicName || value === user?.info.publicName
        setIsPublicNameAvailable(null)
        if (!isFree && value !== user?.info.publicName) {
          setIsPublicNameAvailable(t('errors.api.signUp.displayNameTaken'))
        }
      } catch (e) {
        setIsPublicNameAvailable(t('errors.api.signUp.error'))

        console.log(e)
      }
    }, 400),
    [],
  )

  const cardOrFobTags = user?.tags
    ?.filter((item) => item.tagType === TagTypes.Card || item.tagType === TagTypes.Fob)
    ?.map((tag) => tag.tagNumber)
  const cardOrFobTagToString = cardOrFobTags?.join(', ')

  const appPlayer = user?.tags.find((item) => item.tagType === TagTypes.App)

  const toastMessage = () =>
    toast.success(t('manageAccount.toastMessage'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })

  const initialValues: ProfileData = {
    publicName: user?.info.publicName || '',
    firstName: user?.profile.firstName || '',
    birthDate: user?.profile.birthDate || '',
    email: user?.profile.email || '',
    postCode: user?.profile.postCode || '',
    gender: user?.profile.gender || '',
    card: cardOrFobTagToString || '',
    appPlayerNumber: appPlayer?.tagNumber || '',
    // preferredLanguage: user?.preferredLanguage || '',
    submit: null,
  }

  const postCodeValidation = async (values: ProfileData) => {
    const { postCode } = values
    const errors: FormikErrors<ProfileData> = {}
    try {
      const result = await validatePostcode(postCode, setPostCodeData)
      if (result.statusCode === 404) {
        errors.postCode = result.error
      }
    } catch (e) {}

    return errors
  }

  if (isLoading) {
    return (
      <>
        <SEO title={t('manageAccount.seoTitle')} />
        <Wrapper>
          <Heading primary>{t('manageAccount.title')}</Heading>
        </Wrapper>
        <Wrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <Puff />
        </Wrapper>
      </>
    )
  }

  if (linkedAccount) {
    return (
      <>
        <SEO title={t('manageAccount.seoTitle')} />
        {hasFamily && isParent && (
          <Wrapper width={'400px'}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                marginBottom: 40,
              }}
            >
              <FamilyMemberSelect />
            </div>
          </Wrapper>
        )}
        <Wrapper>
          <Heading primary>{'Linked account'}</Heading>
          <Wrapper width="400px" textAlign="left">
            <Input
              type="text"
              id="publicName"
              text={t('player.displayName') || ''}
              tooltip={<Tooltip text={t('player.displayNameTooltip') || ''} />}
              autoComplete="off"
              value={initialValues.publicName}
              inputProps={{
                title: 'publicName',
                readOnly: true,
              }}
              style={{ marginBottom: '30px' }}
            />

            <Input
              type="text"
              id="firstName"
              autoComplete="given-name"
              text={t('player.firstName') || ''}
              value={initialValues.firstName}
              inputProps={{
                title: 'firstName',
                readOnly: true,
              }}
              style={{ marginBottom: '30px' }}
            />
          </Wrapper>
          <RemoveFromFamilyButton />
        </Wrapper>
      </>
    )
  }

  return (
    <>
      <SEO title={t('manageAccount.seoTitle')} />
      {hasFamily && isParent && (
        <Wrapper width={'400px'}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              marginBottom: 40,
            }}
          >
            <FamilyMemberSelect />
          </div>
        </Wrapper>
      )}
      <CompleteGameSetup />

      <Wrapper>
        <Heading primary>{user?.role.isChild ? t('manageAccount.titleChild') : t('manageAccount.title')}</Heading>
        {masterUser?.id === user?.id && (
          <Wrapper veryTall marginTop="1rem" width="400px" textAlign="left">
            <HandleEmailForm toastmessage={toastMessage} />
          </Wrapper>
        )}
        <Wrapper>
          <h2>{t('manageAccount.details.title')}</h2>
        </Wrapper>
        <Wrapper width="400px" textAlign="left">
          <Formik<ProfileData>
            enableReinitialize
            initialValues={initialValues}
            validationSchema={yourDetailsSchema}
            validate={postCodeValidation}
            onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }): Promise<void> => {
              try {
                if (user?.id) {
                  await onUpdatePlayer({
                    id: user?.id,
                    profile: {
                      firstName: values.firstName,
                      birthDate: values.birthDate,
                      postCode: postCodeData?.postcode || values.postCode,
                      gender: values.gender,
                    },
                    info: {
                      publicName: values.publicName,
                    },
                    // preferredLanguage: values.preferredLanguage,
                  })
                }
                try {
                  if (postCodeData) {
                    await onCreatePostCodeRecord(postCodeData)
                  }
                } catch (e) {
                  console.log('failed to create postcode record')
                }
                // if (game?.supportedLanguages?.length > 1) {
                //   changeLanguage(values.preferredLanguage)
                // }

                resetForm()
                setStatus({ success: true })
                setSubmitting(false)
                toastMessage()
              } catch (error) {
                console.error(error)
                setStatus({ success: false })
                if (error?.errors?.[0].message.includes('Invalid birth date.')) {
                  setErrors({ submit: t('errors.api.account.birthDate') || undefined })
                } else if (error?.errors?.[0].message.includes('Network Error')) {
                  setErrors({ submit: t('errors.network') || undefined })
                } else {
                  setErrors({ submit: t('errors.generic') || undefined })
                }
                setSubmitting(false)
              }
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              touched,
              setFieldValue,
              setFieldError,
            }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <FormItem>
                    <Input
                      type="text"
                      id="publicName"
                      text={t('player.displayName') || ''}
                      tooltip={<Tooltip text={t('player.displayNameTooltip') || ''} />}
                      autoComplete="off"
                      value={values.publicName}
                      onChange={(e) => {
                        setFieldValue('publicName', e.target.value)
                        verifyIsPublicNameAvailable(e.target.value)
                      }}
                      onBlur={handleBlur}
                      hasError={Boolean((touched.publicName && errors.publicName) || isPublicNameAvailable !== null)}
                      error={
                        (touched.publicName && errors.publicName) ||
                        (isPublicNameAvailable ? isPublicNameAvailable : undefined)
                      }
                      inputProps={{
                        title: 'publicName',
                      }}
                    />
                  </FormItem>
                  {values.appPlayerNumber && (
                    <FormItem>
                      <Input
                        id="appPlayerNumber"
                        type="text"
                        text={t('player.playerNumber') || ''}
                        autoComplete="off"
                        disabled
                        value={values.appPlayerNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.appPlayerNumber ? errors.appPlayerNumber : undefined}
                        inputProps={{
                          title: 'App player number',
                        }}
                      />
                    </FormItem>
                  )}
                  <FormItem>
                    <Input
                      id="card"
                      type="text"
                      text={
                        (cardOrFobTags && cardOrFobTags?.length > 1
                          ? t('player.cardNumbers')
                          : t('player.cardNumber')) || ''
                      }
                      autoComplete="off"
                      disabled
                      value={values.card}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.card ? errors.card : undefined}
                      inputProps={{
                        title: 'card number',
                      }}
                      tooltip={<Tooltip text={t('player.cardNumberTooltip') || ''} />}
                    />
                  </FormItem>
                  {!isGameRestricted && <ConnectCardLink />}
                  <FormItem>
                    <Input
                      type="text"
                      id="firstName"
                      autoComplete="given-name"
                      text={t('player.firstName') || ''}
                      value={values.firstName}
                      maxlength={50}
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasError={Boolean(touched.firstName && errors.firstName)}
                      error={touched.firstName ? errors.firstName : undefined}
                      inputProps={{
                        title: 'firstName',
                      }}
                    />
                  </FormItem>
                  <FormItemGrid>
                    <DateInput
                      name="birthDate"
                      maxYear={new Date().getFullYear()}
                      value={values.birthDate}
                      onChange={(date) => setFieldValue('birthDate', date)}
                      setFieldError={setFieldError}
                    />
                  </FormItemGrid>
                  {errors?.birthDate && (
                    <>
                      <p style={{ color: theme.colors.error, display: 'flex', justifyContent: 'center' }}>
                        {errors?.birthDate}
                      </p>
                      <br />
                    </>
                  )}
                  {/*TODO*/}
                  {/*{game?.supportedLanguages?.length > 1 && (*/}
                  {/*  <FormItem>*/}
                  {/*    <SelectDropdown*/}
                  {/*      id="preferredLanguage"*/}
                  {/*      options={SUPPORTED_LANGUAGES.map(({ name, localeCode }) => ({*/}
                  {/*        display: name,*/}
                  {/*        value: localeCode,*/}
                  {/*      }))}*/}
                  {/*      label={t('player.selectPreferredLanguage') || ''}*/}
                  {/*      labelStyle={{ margin: '0 0 1rem 0' }}*/}
                  {/*      ariaLabel={t('player.selectPreferredLanguage') || ''}*/}
                  {/*      placeholder={t('player.selectPreferredLanguage') || ''}*/}
                  {/*      value={values.preferredLanguage}*/}
                  {/*      onBlur={handleBlur}*/}
                  {/*      onChange={handleChange}*/}
                  {/*      secondary*/}
                  {/*      testID="manageAccount_preferredLanguageSelect"*/}
                  {/*    />*/}

                  {/*    <ErrorMessage*/}
                  {/*      name="preferredLanguage"*/}
                  {/*      component={(props) => <Error className="checkboxError" {...props} />}*/}
                  {/*    />*/}
                  {/*  </FormItem>*/}
                  {/*)}*/}

                  <FormItem>
                    <GenderInput
                      onChange={(v) => setFieldValue('gender', v)}
                      value={values.gender}
                      text={t('components.genderInput.gender') || ''}
                    />
                  </FormItem>
                  <FormItem>
                    <Input
                      type="text"
                      id="postCode"
                      autoComplete="postal-code"
                      text={t('player.postCode') || ''}
                      maxlength={20}
                      value={values.postCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={Boolean(touched.postCode && errors.postCode)}
                      error={touched.postCode ? errors.postCode : undefined}
                      inputProps={{
                        title: 'postal code',
                      }}
                    />
                  </FormItem>
                  <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
                  <Button
                    isSubmitting={isSubmitting}
                    disabled={isSubmitting || !isValid}
                    text={t('manageAccount.details.updateButton')}
                    secondary
                  />
                </form>
              )
            }}
          </Formik>
        </Wrapper>
        {!linkedAccount && (
          <>
            <Section />
            {/*<ChangeGame />*/}
            <GameSection />
          </>
        )}
        {isMasterLinkedToFamily && <LeaveFamilySection />}
        {masterUser?.id === user?.id && (
          <>
            <Section />
            <Wrapper>
              <h2>{t('manageAccount.passwordChange.title')}</h2>
            </Wrapper>
            <Wrapper veryTall marginTop="2rem" width="400px" textAlign="left">
              <ChangePasswordForm toastmessage={toastMessage} />
            </Wrapper>
          </>
        )}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Slide}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          bodyStyle={{
            fontSize: '1.8rem',
            fontFamily: 'co-text',
          }}
          style={{
            width: '450px',
          }}
        />
      </Wrapper>
      {masterUser?.id === user?.id &&
        (game?.info.status !== GameStatus.Finished ||
          (game?.info.status === GameStatus.Finished && masterUser?.profile.allowMarketingEmails)) && (
          <>
            <Section />
            <Wrapper>
              <h2>{t('manageAccount.newsletter.title')}</h2>
              <NewsletterButton />
            </Wrapper>
          </>
        )}
      <>
        <Section />
        <Wrapper>
          <DeleteAccountButton />
        </Wrapper>
      </>
    </>
  )
}

export const query = graphql`
  query ManageAccountPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default ManageAccountPage
