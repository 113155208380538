import styled from 'styled-components'
import device, { IS_IE11 } from '../../../styles/device'

export const Container = styled.div`
  width: 100%;
  padding-bottom: 1rem;

  ${(props) =>
    props.home &&
    `
        margin-top: 0;

        @media ${device.mobLandscape} {
            margin-top: 100px;
        }

        @media ${device.tabletLandscape} {
            margin-top: 165px;
        }
    `}
`

export const ImageContainer = styled.div`
  svg {
    ${IS_IE11 ? 'height: 30vw;' : ''}
  }
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  margin-bottom: 22px;
  @media ${device.tabletLandscape} {
    margin-bottom: 30px;
  }

  svg {
    width: 100%;
  }

  @media ${device.tabletLandscape} {
    display: flex;
    align-items: center;
    > div {
      width: 50%;
    }
  }
`

export const FooterLogos = styled.div``
export const FooterTextContainer = styled.div`
  margin: 0 auto;
  min-width: 60%;
  @media ${device.desktop} {
    min-width: auto;
  }
`
export const FooterText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.midGrey};

  ${(props) =>
    props.partners &&
    `
    @media ${device.tabletLandscape} {
          align-items: flex-start; 
          margin-left: 70px;   
        }
        

  @media ${device.desktop} {
    margin-left: 100px;
  }    
    `}

  p,
  a {
    font-size: 1.2rem;
    line-height: 1.2rem;

    text-align: center;

    @media ${device.tabletLandscape} {
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-align: left;
    }
  }

  p {
    font-size: 1.2rem;
    margin-top: 5px;
    order: 1;
    @media ${device.tabletLandscape} {
      order: 2;
    }
  }
`
export const FooterLinks = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  ${(props) =>
    props.partners &&
    `
      @media ${device.tabletLandscape} {
          margin-bottom: 5px;
        }
      }
          
    `}

  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    order: 2;

    ${(props) =>
      props.partners &&
      `
      @media ${device.tabletLandscape} {
          justify-content: flex-start;
        }
      }
          
    `}

    ${(props) =>
      props.isGamePage &&
      `
      @media ${device.tabletLandscape} {
          justify-content: center;
        }
      }
          
    `}

    @media ${device.tabletLandscape} {
      padding: 0;
      order: 1;
      flex-wrap: nowrap;
      margin-bottom: 5px;
    }

    padding-bottom: 8px;
  }
`
export const FooterLink = styled.span`
  border-right: 1px solid ${(props) => props.theme.colors.lightGrey};

  a {
    color: ${(props) => props.theme.colors.midDarkBlue};
  }

  margin: 5px 0 5px 0;
  padding: 0 5px 0 5px;
  white-space: nowrap;

  @media ${device.tabletLandscape} {
    padding: 0 10px 0 10px;
  }

  &:first-child {
    padding: 0 5px 0 0;

    @media ${device.tabletLandscape} {
      padding: 0 10px 0 0;
    }
  }

  &:last-child {
    padding: 0 0 0 5px;
    border: none;

    @media ${device.tabletLandscape} {
      padding: 0 0 0 10px;
    }
  }
`

export const StyledHelpText = styled.button`
  display: inline-block;
  background-color: transparent;
  color: #007cbd;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 1.2rem;
  padding: 0;

  @media ${device.tabletLandscape} {
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-align: left;
  }
  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }
  &:focus {
    text-decoration: underline;
    background-color: transparent;
  }
  &:active {
    outline: none;
  }
`
