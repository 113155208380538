import React, { FC } from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import Wrapper from '../../components/Wrapper/Wrapper'
import { Heading } from '../../components/Heading/Heading.styled'
import RichText from '../../components/RichText'
import useGame from '../../contexts/GameContext/useGame'
import { IPageProps } from '../../contexts/PageContext'

const AccessibilityStatementPage: FC<IPageProps<Queries.AccessibilityStatementPageQuery>> = ({ data }) => {
  const { title, body } = data.sanityHomepage?.accessibility || {}
  const { game } = useGame()

  return (
    <>
      <SEO title={title || 'Accessibility Statement'} />
      <Wrapper width="850px" textAlign="left">
        <Heading contentPage>{title}</Heading>
        <RichText blocks={body} context={{ game }} />
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query AccessibilityStatementPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }, i18n_lang: { eq: $language }) {
      accessibility {
        id
        title
        body: _rawBody
      }
      partners {
        id
        name
        websiteUrl
        logo {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default AccessibilityStatementPage
