import { set, sub } from 'date-fns'

const getValidDate = (isChild: boolean) => {
  const adultValidDate = set(sub(new Date(), { years: 13 }), { date: 28, hours: 0, minutes: 0, seconds: 0 })
  const childValidDate = set(sub(new Date(), { years: 13 }), { date: 1, hours: 0, minutes: 0, seconds: 0 })

  if (isChild) {
    return childValidDate
  } else {
    return adultValidDate
  }
}

export default getValidDate
