import styled from 'styled-components'
import device from '../../styles/device'

export const LogoContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  align-content: center;
  margin: 1rem auto;
  min-width: calc(300px + 2rem);

  @media ${device.tabletPortrait} {
    margin: 2rem auto;
  }
  > div {
    width: 50%;

    @media ${device.mobLandscape} {
      width: ${({ logoCount }) => (logoCount < 4 ? `${Math.round(100 / logoCount)}%` : '25%')};
    }
    padding: 10px;
    box-sizing: border-box;
  }
  a {
    width: 100%;
    max-width: 125px;
    margin: 0.5rem auto;
    @media ${device.mobPortrait} {
      max-width: 150px;
    }

    @media ${device.mobLandscape} {
      margin: 0.5rem 1rem;
      max-width: 175px;
    }

    @media ${device.tabletPortrait} {
      margin: 0.5rem 2rem;
    }
  }
`

export const FooterLogoContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  min-width: calc(300px + 2rem);
  display: none;
  @media ${device.tabletLandscape} {
    display: flex;
    margin: 2rem auto;
  }

  height: 100%;

  @media ${device.tabletPortrait} {
    margin: 0 auto;
  }
  @media ${device.tabletLandscape} {
    margin: 0 70px 0 0;
    justify-content: flex-end;
  }

  @media ${device.desktop} {
    margin: 0 100px 0 0;
  }
  > div {
    width: ${({ logoCount }) => (logoCount < 4 ? `${Math.round(100 / logoCount)}%` : '25%')};
    max-width: 125px;
    padding: 15px;
    box-sizing: border-box;
  }
  a {
    width: 100%;
    max-width: 70px;
    margin: 0 0.8rem 0 0;

    &:last-child {
      margin: 0;
    }
    @media ${device.mobPortrait} {
      max-width: 80px;
      margin: 0 2rem 0 0;
    }

    @media ${device.mobLandscape} {
      max-width: 110px;
    }
  }
`
