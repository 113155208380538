import React, { FC, useEffect, useRef, useState } from 'react'
import BackgroundArc from '../../components/BackgroundArc/BackgroundArc'
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import { IPageProps } from '../../contexts/PageContext'
import SVGImage from '../../components/DynamicImg/SVGImage'
import { Divider, GroupImageWrapper } from './get-started.styled'
import BeginSection from './sections/BeginSection'
import WalkSection from './sections/WalkSection'
import HowToPlaySection from './sections/HowToPlaySection'
import ReadyToPlaySection from './sections/ReadyToPlaySection'
import BottonHelpSection from './sections/BottonHelpSection'
import useAuth from '../../contexts/AuthContext/useAuth'

const GetStartedPage: FC<IPageProps<Queries.GetStartedPageQuery>> = () => {
  const { user } = useAuth()
  const [playNowLink, setPlayNowLink] = useState('/login')

  useEffect(() => {
    if (!user) {
      setPlayNowLink('/login')
    } else {
      if (user.info.gameId) {
        setPlayNowLink('/landing')
      } else {
        setPlayNowLink('/join-game')
      }
    }
  }, [user])
  const handleOpenHelpDesk = () => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.FreshworksWidget('open')
    }
  }

  const carouselRef = useRef<HTMLButtonElement>()

  const handleScroll = () => {
    carouselRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <SEO title={'Get started'} />
      <BackgroundArc isGetStarted={true} isBluePartBigger={true} />
      <BeginSection scroll={handleScroll} link={playNowLink} />
      <GroupImageWrapper>
        <SVGImage
          filename="group-of-people"
          alt={'group of people'}
          svgProperties={{
            width: '100%',
            height: '80vw',
          }}
        />
      </GroupImageWrapper>
      <WalkSection />
      <Divider />
      <HowToPlaySection ref={carouselRef} />
      <ReadyToPlaySection handleOpenHelpDesk={handleOpenHelpDesk} scroll={handleScroll} link={playNowLink} />
      <BottonHelpSection handleOpenHelpDesk={handleOpenHelpDesk} />
    </>
  )
}

export default GetStartedPage

export const query = graphql`
  query GetStartedPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }, i18n_lang: { eq: $language }) {
      partners {
        id
        name
        websiteUrl
        logo {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
