import styled from 'styled-components'
import device from '../../../styles/device'

export const StyledFooter = styled.footer`
  border-top: 1px dashed #eaeaea;
  border-top-width: 1px;
  max-width: ${(props) => props.theme.siteWidth};
  margin: 1rem auto 0;
  width: 90%;
  padding: 1rem;

  @media ${device.mobLandscape} {
    margin: 4rem auto 0;
    margin-top: 15rem;
    padding: 2rem;
  }
`

export const FooterInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  @media ${device.tabletPortrait} {
    display: flex;
    justify-content: center;
  }

  p {
    color: ${(props) => props.theme.colors.midGrey};
    margin: 0 0 1rem;

    @media ${device.tabletPortrait} {
      margin: 5px 1.4rem 5px 0;
    }
    @media ${device.tabletLandscape} {
      width: 250px;
    }
  }

  p,
  a {
    font-size: 1.2rem;
    line-height: 1.2rem;

    text-align: center;

    @media ${device.tabletLandscape} {
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-align: left;
    }
  }
`

export const FooterLinks = styled.nav`
  display: flex;
  flex-direction: column;

  > div {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  a {
    color: ${(props) => props.theme.colors.midDarkBlue};
  }
  padding: 0 5px 0 5px;

  @media ${device.tabletLandscape} {
    padding: 0 10px 0 10px;
  }
`
